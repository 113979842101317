import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { Email, SupportEmail } from 'src/app/shared/models/email.models';
import { EntryResponse } from 'src/app/shared/models/generic.models';

@Injectable({
  providedIn: 'root'
})
export class EmailApiService {

  constructor(private http: HttpClient) { }

  createAndSendSupportEmail(supportEmail: SupportEmail): Observable<Email> {
    return this.http.post<EntryResponse<Email>>('/api/Emails/Support', supportEmail)
      .pipe(map((x: EntryResponse<Email>) => x.Entry));
  }
}
