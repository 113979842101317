import { supportTopicId } from '../enums/support-topic-id.enum';
import { SupportEmail, SupportTopic } from '../models/email.models';
import { User } from '../models/user.models';

export const createSupportEmailTemplate = (user: User, message: string, supportTopic?: SupportTopic): SupportEmail => {
  const d = new Date();
  const time = d.toLocaleTimeString();
  const date = d.toLocaleDateString();
  const year = d.getFullYear();
  const phoneNumber = user.Phone || 'No number given';
  const title = supportTopic ? '<title>FIG Agent Portal Support Request - ' + supportTopic.Value + '</title>' : '<title>FIG Agent Portal Support Request</title>'; //TODO: I dont think this shows up in the email anywhere
  const topic = supportTopic ? '<p><strong>Request Type: </strong> ' + supportTopic.Value + '</p>' :  '<p><strong>Request Type: General Support</strong>';
  const body = '<html>' +
        '<head>' +
        '<meta charset=\'utf-8\'>' +
        title +
        '</head>' +
        '<body>' +
        '<table width=\'80%\' align=\'center\' cellpadding=\'0\' cellspacing=\'0\'>' +
        '<tr>' +
        '<td height=\'80\'><span style=\'display:block; font-size: 25px;\' />FIG Agent Portal Support Request</span><br></td>' +
        '</tr>' +
        '<tr>' +
        '<td style=\'border:#ddd 1px solid;\'></td>' +
        '</tr>' +
        '<tr>' +
        '<td style=\'padding:20px 20px; font-family:Arial, Helvetica, sans-serif; color:#5B5B5B ; line-height:18px;\'>' +
        '<p>The following support request was submitted via the agent portal at ' + time + ' on ' + date + '</p>' +
        '<p><strong>Full Name:</strong> ' + user.FullName + '</p>' +
        '<p><strong>Email: </strong> ' + user.Email + '</p>' +
        '<p><strong>Phone: </strong> ' + phoneNumber + '</p>' +
        topic +
        '<p><strong>Message: </strong> ' + message + '</p>' +
        '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style=\'border:#ddd 1px solid;\'></td>' +
        '</tr>' +
        '<tr>' +
        '<td height=\'30\' align=\'center\' style=\'font-family:Arial, Helvetica, sans-serif; font-size:10px; color:#5B5B5B ;\'>' +
        'Copyright &copy; ' + year + '  All Rights Reserved. <a href=\'http://www.figmarketing.com\'><font color=\'#5B5B5B \'>Financial Independence Group, Inc.</font></font></a>' +
        '</td>' +
        '</tr>' +
        '</table>' +
        '</body>' +
        '</html>';

  const supportEmail: SupportEmail = {
    Email: {
      Subject: 'FIG Agent Portal Support Request - ' + user.FullName,
      Body: body,
      IsImportant: false,
      SentDate: d
    },
    SupportTopic: supportTopic ? supportTopic : undefined
  };

  return supportEmail;
};

export const EmailRequestTypes: SupportTopic[] = [
  {
    Id: supportTopicId.SalesTeam,
    Value: 'Quotes/Quoting Tool Questions',
  },
  {
    Id: supportTopicId.SalesTeam,
    Value: 'Contracting Questions',
  },
  {
    Id: supportTopicId.SalesTeam,
    Value: 'Product Questions',
  },
  {
    Id: supportTopicId.HelpDesk,
    Value: 'Portal Technical Issues/Other',
  }
];