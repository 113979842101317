<form [formGroup]="supportForm" (ngSubmit)="onSubmit(supportForm)" appCyData="supportForm"
  class="relative flex flex-col w-full h-full">
  <section class="flex items-center flex-none p-20 border-b border-solid border-gray-lighter">
    <h2 class="m-10 text-lg font-bold">Contact Support</h2>
    <button mat-button tabindex="-1" type="button" mat-dialog-close class="m-10 ml-auto icon-button is-primary is-inverted"><i
    class="material-icons">close</i></button>
  </section>
  <section class="grid grid-cols-2 overflow-auto text-base p-30 gap-y-20 gap-x-20">
    <p class="col-span-2 text-lg">To submit a support ticket, please fill the form below and we will contact you as soon as
    possible. Thank you!</p>
    <div class="flex flex-col col-span-2">
      <label for="fullName" class="mb-10 text-sm font-medium text-gray required-label">Full Name</label>
      <input formControlName="FullName" name="fullName" id="fullName" class="input" type="text" appCyData="fullNameInput" />
      @if (supportForm.controls.FullName.touched && supportForm.controls.FullName.invalid) {
        <div class="mt-5 text-sm text-red">
          @if (supportForm.controls.FullName.errors?.required) {
            <div>Please enter a name</div>
          }
        </div>
      }
    </div>
    <div class="flex flex-col col-span-2 md:col-span-1">
      <label for="email" class="mb-10 text-sm font-medium text-gray required-label">Email</label>
      <input formControlName="Email" name="email" id="email" class="input" type="email" appCyData="emailInput" />
      @if (supportForm.controls.Email.touched && supportForm.controls.Email.invalid) {
        <div class="mt-5 text-sm text-red">
          @if (supportForm.controls.Email.errors?.required || supportForm.controls.Email.errors?.email) {
            <div>Please enter a valid email adress</div>
          }
        </div>
      }
    </div>
    <div class="flex flex-col col-span-2 md:col-span-1">
      <label for="phone" class="mb-10 text-sm font-medium text-gray">Phone</label>
      <input formControlName="Phone" name="phone" id="phone" class="input" appCyData="phoneInput" />
      @if (supportForm.controls.Phone.touched && supportForm.controls.Email.invalid) {
        <div class="mt-5 text-sm text-red">
          @if (supportForm.controls.Phone.errors?.required) {
            <div>Please enter a valid phone number</div>
          }
        </div>
      }
    </div>
    <div class="flex flex-col col-span-2">
      <label for="supportTopic" class="mb-10 text-sm font-medium text-gray required-label">Reason to Contact Support</label>
      <mat-select class="select" placeholder="What do you need help with?" formControlName="SupportTopic" name="supportTopic" appCyData="reasonSelect">
        @for (item of supportTopics; track item) {
          <mat-option [value]="item">
            {{item.Value}}
          </mat-option>
        }
      </mat-select>
      @if (supportForm.controls.SupportTopic.touched && supportForm.controls.SupportTopic.invalid) {
        <div class="mt-5 text-sm text-red">
          @if (supportForm.controls.SupportTopic.errors?.required) {
            <div>Please select a support topic</div>
          }
        </div>
      }
    </div>
    <div class="flex flex-col col-span-2">
      <label for="message" class="mb-10 text-sm font-medium text-gray required-label">Message</label>
      <textarea formControlName="Message" name="message" id="message" class="textarea" rows="3" type="text" appCyData="messageInput"></textarea>
      @if (supportForm.controls.Message.touched && supportForm.controls.Message.invalid) {
        <div class="mt-5 text-sm text-red">
          @if (supportForm.controls.Message.errors?.required) {
            <div>Please enter a valid message</div>
          }
        </div>
      }
    </div>
  </section>
  <section class="flex p-20 mt-auto border-t border-solid border-gray-lighter">
    <button mat-button type="submit" class="m-10 ml-auto button is-primary" [disabled]="loading" appCyData="submitBtn">Submit</button>
  </section>
</form>